import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

const ContentPage = ({ data, location, ...props }) => {
    return (
        <SeoPage
            leadImage={data.img0.childImageSharp.fluid.src}
            location={location}
            title="Comment obtenir un pardon pour alcool au volant au Québec?"
            description="Vous avez été reconnu coupable de conduite avec les facultés affaiblies par l’alcool et souhaitez obtenir un pardon ? Nous vous expliquons comment l’obtenir!"
            fr={undefined}
            lawType="criminal"
            specialtyText="pour obtenir un pardon">
            <p>
                <strong>
                    Être reconnu coupable de conduite avec les facultés
                    affaiblies par l’alcool entraîne son lot de conséquences,
                    dont l’ouverture d’un casier judiciaire
                </strong>
                .{' '}
            </p>
            <p>
                Avoir un casier judiciaire entraîne également plusieurs
                limitations dans la vie de tous les jours.
            </p>
            <p>
                <Img
                    fluid={data.img0.childImageSharp.fluid}
                    title="Demande de pardon pour conduite avec facultés affaiblies!"
                    alt=""
                />
            </p>
            <p>
                <strong>Alors, vient le besoin d’obtenir un pardon!</strong>{' '}
                Pour faire une demande de pardon après avoir été reconnu
                coupable de conduite avec les facultés affaiblies par l'alcool,
                il est important de comprendre plusieurs aspects de ce processus
                et d’anticiper les différentes étapes nécessaires!
            </p>
            <p>
                <strong>Dans cet article, nous examinerons en détail :</strong>
            </p>
            <ul>
                <li>
                    Ce qu’implique une demande de pardon pour conduite avec les
                    facultés affaiblies{' '}
                </li>
                <li>Les critères d’admissibilité </li>
                <li>Les étapes à suivre pour obtenir un pardon </li>
                <li>Les avantages d’un pardon</li>
            </ul>
            <h2>
                Qu’est-ce qu’une demande de pardon pour conduite avec facultés
                affaiblies par l’alcool ?
            </h2>
            <p>
                Une demande de pardon pour conduite avec les facultés affaiblies
                par l’alcool est une procédure légale qui permet à une personne
                reconnue coupable d'une telle infraction de voir son casier
                judiciaire effacé des vérifications courantes.{' '}
            </p>
            <table>
                <tr>
                    <td>
                        <h4>Le pardon n’efface pas l’infraction!</h4>
                        <p>
                            Il est important de noter que cette procédure ne
                            constitue pas une absolution de l'infraction,
                            c'est-à-dire qu'elle ne supprime pas l'acte criminel
                            lui-même.{' '}
                        </p>
                        <p>
                            L'infraction en question reste enregistrée dans les
                            archives de la Gendarmerie royale du Canada, mais
                            elle devient confidentielle et n’apparaît plus lors
                            des vérifications de casier judiciaire réalisées par
                            des employeurs ou d’autres entités, sauf dans des
                            circonstances spécifiques où l'accès à ces
                            informations est requis, par exemple, pour des
                            raisons de sécurité publique.
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                <strong>
                    Le but principal d'une demande de pardon n'est pas d'effacer
                    les faits, mais de limiter les conséquences de l'infraction.
                </strong>{' '}
            </p>
            <p>
                Une fois que la demande est acceptée, la personne concernée
                bénéficie d'une forme de réhabilitation, ce qui signifie que son
                casier judiciaire ne sera plus accessible au public, lui
                permettant ainsi de se réintégrer plus facilement dans la
                société sans être constamment pénalisé pour un acte passé.
            </p>
            <p>
                <Img
                    fluid={data.img1.childImageSharp.fluid}
                    title="Pourquoi faire une demande de pardon?"
                    alt=""
                />
            </p>
            <p>
                Il est nécessaire de comprendre que, bien que l'infraction ne
                soit plus visible aux yeux du public, elle demeure toujours dans
                les archives de la Gendarmerie royale du Canada, ce qui signifie
                qu'elle peut être consultée par certaines autorités ou en cas de
                procédure judiciaire, mais ne sera pas accessible dans le cadre
                d'une vérification standard.{' '}
            </p>
            <h3>Pourquoi faire une demande de pardon ?</h3>
            <p>
                Faire une demande de pardon permet à une personne de prendre ses
                responsabilités tout en cherchant à se réhabiliter et à alléger
                les conséquences d'une infraction commise. Bien que la demande
                de pardon ne supprime pas l'infraction elle-même, elle offre
                plusieurs avantages significatifs!
            </p>
            <h4>
                Voici les principales raisons pour lesquelles il est important
                de faire une demande de pardon:
            </h4>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Avantages d’un pardon</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Explication</strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Faciliter l’obtention d’un emploi</p>
                    </td>
                    <td>
                        <p>
                            <strong>
                                Un casier judiciaire peut avoir un impact direct
                                sur l’obtention d'un emploi
                            </strong>
                            .{' '}
                        </p>
                        <p>
                            Dans de nombreux secteurs, notamment ceux impliquant
                            un contact avec le public ou régies par des normes
                            de sécurité strictes (comme la santé ou les
                            finances), il peut constituer un frein considérable.{' '}
                        </p>
                        <p>
                            Les employeurs sont souvent réticents à embaucher
                            une personne ayant une condamnation criminelle, même
                            si celle-ci a purgé sa peine et entrepris des
                            démarches de réhabilitation.
                        </p>
                        <p>
                            La demande de pardon permet de lever cette barrière
                            en rendant l'infraction inaccessible ou difficile
                            d'accès lors des vérifications de casier judiciaire.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Faciliter l’accès à la location d’un logement</p>
                    </td>
                    <td>
                        <p>
                            Un casier judiciaire peut également affecter la
                            capacité de louer un logement. De nombreux
                            propriétaires ou agences immobilières effectuent des
                            vérifications de casier judiciaire avant de signer
                            un contrat de location.
                        </p>
                        <p>
                            <strong>
                                Il n’est pas illégal pour un locateur de
                                discriminer un candidat en lui refusant un
                                logement en raison de son casier judiciaire
                            </strong>
                            , même si la condamnation pour conduite avec
                            facultés affaiblies remonte à plusieurs années.
                        </p>
                        <p>
                            Faire une demande de pardon permet de contourner
                            cette situation. Cela offre ainsi à l’individu de
                            meilleures chances d’accéder à un logement, sans
                            être jugé pour une erreur du passé.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Faciliter les voyages à l’international</p>
                    </td>
                    <td>
                        <p>
                            Les personnes ayant un casier judiciaire peuvent
                            également rencontrer des difficultés lorsqu’elles
                            souhaitent voyager à l'étranger.{' '}
                        </p>
                        <p>
                            <strong>Certains pays</strong>, notamment les
                            États-Unis, sont particulièrement stricts en matière
                            d'immigration et{' '}
                            <strong>
                                refusent l’entrée à toute personne ayant un
                                casier judiciaire
                            </strong>
                            , même si l'infraction est mineure ou a été commise
                            il y a plusieurs années.
                        </p>
                        <p>
                            La demande de pardon permet de rendre l'infraction
                            plus difficilement accessible lors des demandes de
                            visa ou des contrôles aux frontières, facilitant
                            ainsi les voyages internationaux.
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                <Img
                    fluid={data.img2.childImageSharp.fluid}
                    title="Obtenir un pardon facilite les voyages à l’international!"
                    alt=""
                />
            </p>
            <p>
                Bref, faire une demande de pardon permet à une personne de
                réduire les impacts négatifs d’un casier judiciaire, de se
                réhabiliter et de se réintégrer pleinement dans la société. Cela
                aide à lever des obstacles importants à l’obtention d’un emploi,
                à la location d’un logement et aux déplacements internationaux.
            </p>
            <h2>Le processus de demande de pardon!</h2>
            <p>
                Avant d'entreprendre la procédure de demande de pardon, il est
                important de comprendre les différentes étapes à suivre, les
                critères d'admissibilité et les documents nécessaires !
            </p>
            <h3>Vérification de l’éligibilité </h3>
            <p>
                Avant de commencer la procédure de demande de pardon, il est
                essentiel de vérifier si vous êtes éligible.{' '}
            </p>
            <p>
                En vertu de la{' '}
                <Link to="https://laws-lois.justice.gc.ca/fra/lois/C-47/">
                    <em>Loi sur le casier judiciaire</em>
                </Link>
                , nul n’est admissible à présenter une demande de suspension du
                casier (demande de pardon) avant que la période consécutive à
                l’expiration légale de la peine, notamment une peine
                d’emprisonnement, une période de probation ou le paiement d’une
                amende, énoncée ci-après, ne soit écoulée :
            </p>
            <ul>
                <li>
                    <strong>10 ans</strong> pour la conduite avec les facultés
                    affaiblies par l’alcool qui a fait l’objet d’une poursuite
                    par <strong>voie de mise en accusation</strong>.
                </li>
                <li>
                    <strong>5 ans</strong> pour la conduite avec les facultés
                    affaiblies par l’alcool qui est punissable sur déclaration
                    de culpabilité par procédure sommaire.
                </li>
            </ul>
            <p>
                En d'autres termes, si vous avez été condamné pour conduite avec
                facultés affaiblies, vous devrez attendre 5 ou 10 ans{' '}
                <strong>après avoir purgé votre peine</strong> avant de pouvoir
                entamer une demande de pardon. Si l'infraction a été jugée par
                procédure sommaire, l'attente sera de 5 ans, tandis qu'une mise
                en accusation nécessitera 10 ans avant de pouvoir commencer la
                démarche.
            </p>
            <h3>Collecte des documents nécessaires</h3>
            <p>
                Pour préparer votre demande de pardon, il est important de
                rassembler plusieurs documents afin de compléter votre dossier.
                Cela peut sembler un peu compliqué, mais chaque document a son
                rôle spécifique dans le processus.
            </p>
            <ul>
                <li>
                    <strong>Le casier judiciaire</strong> : La première étape
                    consiste à obtenir votre casier judiciaire auprès de la
                    Gendarmerie royale du Canada. Cela peut nécessiter la prise
                    de vos empreintes digitales, et vous devrez vous rendre dans
                    un poste de police pour cette étape.{' '}
                </li>
                <li>
                    <strong>La preuve de condamnation</strong> : Si l’infraction
                    n’apparaît pas directement sur votre casier judiciaire, vous
                    devrez fournir un document officiel de la cour qui prouve
                    votre condamnation. Cela peut inclure des copies des
                    jugements rendus lors de votre procès.
                </li>
                <li>
                    <strong>Les informations de la cour</strong> : Vous devrez
                    aussi fournir les détails concernant votre condamnation,
                    comme les peines prononcées et la date de la condamnation.{' '}
                </li>
            </ul>
            <p>
                <Img
                    fluid={data.img3.childImageSharp.fluid}
                    title="Le processus de demande de pardon!"
                    alt=""
                />
            </p>
            <ul>
                <li>
                    <strong>Les vérifications de police locale</strong> : En
                    fonction des endroits où vous avez vécu dans les cinq
                    dernières années, vous devrez fournir des vérifications des
                    dossiers de police locaux.
                </li>
                <li>
                    <strong>Photocopie d’une pièce d’identité</strong> : Il vous
                    sera demandé de fournir une copie d’un document d'identité
                    valide, comme un passeport ou un permis de conduire.
                </li>
                <li>
                    <strong>Autres vérifications</strong> : Si vous avez servi
                    dans les Forces armées canadiennes, un rapport de conduite
                    militaire est nécessaire, ainsi que d’autres documents en
                    fonction de votre situation.
                </li>
            </ul>
            <h3>Préparation des formulaires requis</h3>
            <p>
                Une fois que vous avez rassemblé tous les documents nécessaires,
                vous pouvez entamer la partie suivante du processus : remplir
                les formulaires pour votre demande de pardon.
            </p>
            <h4>
                Le formulaire de demande de suspension du casier judiciaire{' '}
            </h4>
            <p>
                Ce formulaire, que vous pouvez obtenir auprès de la Commission
                des libérations conditionnelles du Canada, vous demande de
                fournir des informations personnelles importantes telles que:
            </p>
            <ul>
                <li>Votre nom</li>
                <li>Votre date de naissance</li>
                <li>Votre sexe</li>
                <li>Votre numéro de permis de conduire (si applicable)</li>
                <li>
                    Votre adresse actuelle et les adresses précédentes des dix
                    dernières années
                </li>
            </ul>
            <p>
                Il est important de veiller à ce que toutes les informations
                soient complètes et exactes afin d'éviter tout retard ou rejet
                de votre demande.
            </p>
            <h4>
                Le formulaire Bénéfice mesurable/
                <strong>
                    <strong>r</strong>
                </strong>
                éadaptation{' '}
            </h4>
            <p>
                Ce formulaire est essentiel pour démontrer que la suspension de
                votre casier judiciaire vous apportera des bénéfices concrets.
                Vous devrez expliquer en détail comment cela contribuera à votre
                réintégration dans la société, en tant que citoyen respectueux
                des lois.{' '}
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>
                                L'objectif est de prouver que vous avez
                                véritablement changé et que cette demande de
                                pardon n'est pas simplement une démarche pour
                                faciliter votre vie!
                            </strong>
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                Une partie importante de cette étape consiste à démontrer vos
                efforts de réadaptation. Cela inclut de prouver que vous avez
                travaillé sur votre réhabilitation en participant à des
                programmes de soutien ou en suivant des thérapies.{' '}
            </p>
            <p>
                Vous pouvez également fournir des lettres de référence
                d’employeurs ou de professionnels qui attesteront de votre bonne
                conduite et de vos efforts.
            </p>
            <h3>Soumettre la demande et attendre</h3>
            <p>
                Une fois le dossier complet, vous pouvez soumettre votre demande
                à la Commission des libérations conditionnelles du Canada.{' '}
            </p>
            <p>
                À cette étape, des frais de traitement seront appliqués,
                généralement autour de <strong>50$.</strong> Le processus peut
                prendre de <strong>6 à 12 mois</strong>, voire plus, selon la
                complexité de votre dossier et les vérifications nécessaires.
            </p>
            <h3>Traitement de la demande et réponse</h3>
            <p>
                La dernière étape de la procédure de demande de pardon consiste
                en l'examen et le traitement de votre demande par la Commission
                des libérations conditionnelles du Canada. Elle évalue plusieurs
                critères avant de rendre sa décision.
            </p>
            <p>
                <Img
                    fluid={data.img4.childImageSharp.fluid}
                    title="Traitement de la demande de pardon par la Commission!"
                    alt=""
                />
            </p>
            <h4>
                Durée écoulée depuis la{' '}
                <strong>
                    <strong>condamnation</strong>
                </strong>
            </h4>
            <p>
                Tout d’abord, la Commission vérifie que suffisamment de temps
                s'est écoulé depuis votre condamnation et que vous avez purgé
                toutes les peines imposées, y compris toute période de probation
                ou d'amende.
            </p>
            <h4>
                Comportement depuis la{' '}
                <strong>
                    <strong>condamnation</strong>
                </strong>
            </h4>
            <p>
                Ensuite, votre comportement depuis la condamnation est scruté.
                Si vous avez maintenu une conduite irréprochable, sans nouvelles
                infractions criminelles, et si vous avez participé à des
                programmes de réhabilitation, cela renforcera considérablement
                votre demande. En revanche, toute infraction postérieure
                pourrait nuire à la recevabilité de votre dossier.
            </p>
            <h4>Les circonstances entourant la commission de l’infraction</h4>
            <p>
                Les circonstances spécifiques de l'infraction sont également
                prises en compte. Par exemple, la Commission peut examiner des
                éléments tels que votre taux d’alcoolémie au moment de
                l'infraction, votre attitude avec les policiers au moment de
                l’arrestation, ainsi que la présence d'autres passagers dans le
                véhicule, en particulier des personnes vulnérables, comme des
                enfants.
            </p>
            <p>
                Ces facteurs peuvent jouer un rôle important dans l’évaluation
                de la gravité de l'infraction et, par conséquent, dans la
                décision concernant votre demande de pardon.
            </p>
            <h4>Évaluation de l'administration de la justice</h4>
            <p>
                Enfin, la Commission évalue si la suspension du casier
                judiciaire pourrait compromettre l’administration de la justice.
                Elle considère la gravité de l’infraction, la durée de la peine
                et les circonstances qui l’entourent pour déterminer si la
                demande de pardon peut être accordée. Si tous ces éléments sont
                favorables, votre demande de pardon sera acceptée.
            </p>
            <h2>
                Vous désirez obtenir un pardon ? Consultez un avocat spécialisé
                en droit criminel !
            </h2>
            <p>
                Bien que vous puissiez soumettre une demande de pardon
                vous-même, il est fortement recommandé de travailler avec un
                avocat spécialisé en droit criminel.
            </p>
            <p>
                {' '}
                Un avocat pourra vous guider dans la collecte des documents
                nécessaires, s’assurer que tous les formulaires sont
                correctement remplis et vous donner des conseils précieux sur la
                manière de présenter votre demande de la manière la plus
                convaincante possible.{' '}
            </p>
            <p>
                Faire appel à un avocat spécialisé en droit criminel pour vous
                accompagner tout au long de ce processus peut grandement
                faciliter les démarches et augmenter vos chances de succès!
            </p>
            <p>
                <Img
                    fluid={data.img5.childImageSharp.fluid}
                    title="Avocat pour demande de pardon!"
                    alt=""
                />
            </p>
            <p>
                <strong>
                    Grâce à notre formulaire simple et gratuit, vous pourrez
                    rapidement trouver un avocat spécialisé en droit criminel
                    pour vous accompagner dans votre demande de pardon !
                </strong>
            </p>
            <p>
                <strong>
                    Après avoir soumis votre demande, nous vous mettrons en
                    relation avec un avocat proche de chez vous. Ce service ne
                    vous engage en rien !
                </strong>
            </p>
        </SeoPage>
    )
}

export default ContentPage

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "obtenir-pardon-alcool-volant-quebec/demande-pardon-conduite-facultes-affaiblies.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "obtenir-pardon-alcool-volant-quebec/pourquoi-faire-demande-pardon.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "obtenir-pardon-alcool-volant-quebec/obtenir-pardon-facilite-voyages-international.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "obtenir-pardon-alcool-volant-quebec/processus-demande-pardon.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img4: file(
            relativePath: {
                eq: "obtenir-pardon-alcool-volant-quebec/demande-pardon-commission-libération-conditionnelles.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img5: file(
            relativePath: {
                eq: "obtenir-pardon-alcool-volant-quebec/avocat-demande-pardon-quebec.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
